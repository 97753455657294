import React from 'react'
import { Seo, PageHeader } from '../components'
import HtmlRender from '../components/HtmlRender/HtmlRender'

// Styles
import { ContentWrapper } from '../styles/PrivacyPolicyPage.style'

import { usePrismic } from '../context/PrismicContext'

const PrivacyPolicyPage = () => {
  const {
    prismicData: {
      prismicPrivacyPolicyPage: { page_title, privacy_content },
    },
  } = usePrismic()

  return (
    <>
      <Seo title={page_title[0].text} />
      <ContentWrapper>
        <PageHeader exitRoute="/">{page_title[0].text}</PageHeader>

        <HtmlRender content={privacy_content} />
      </ContentWrapper>
    </>
  )
}

export default PrivacyPolicyPage
